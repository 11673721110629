<template>
  <div>
    <a-row class="mb-10 header" style="padding-bottom: 0">
      <a-col :span="10">
        <h2 class="upper mt-10">
          <a-icon type="credit-card" /> CARTÃO DE CRÉDITO ID
          {{ creditCardId }}
        </h2>
        <div class="txt name">
          {{
            creditCard.customer
              ? creditCard.customer.first_name +
                " " +
                creditCard.customer.last_name
              : " "
          }}
        </div>
        <div class="txt">
          Fechamento da Fatura:
          {{ ("0" + creditCard.invoice_closing_date).slice(-2) }}/{{
            new Date().getMonth() + 1
          }}/{{ new Date().getFullYear() }}
        </div>
        <div class="txt">
          Pagamento da Fatura:
          {{ ("0" + creditCard.invoice_payment_date).slice(-2) }}/{{
            new Date().getMonth() + 1
          }}/{{ new Date().getFullYear() }}
        </div>
      </a-col>
      <a-col class="cards" :span="14">
        <a-row :gutter="20">
          <a-col :span="8">
            <div class="box total">
              <div class="name">LIMITE DO CARTÃO</div>
              <div class="number">
                {{ formatPricePtBr(creditCard.card_limit) }}
              </div>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="box available">
              <div class="name">SALDO DISPONÍVEL</div>
              <div class="number">
                {{ formatPricePtBr(creditCard.available_value) }}
              </div>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="box used">
              <div class="name">SALDO UTILIZADO</div>
              <div class="number">
                {{ formatPricePtBr(creditCard.spent_value) }}
              </div>
            </div>
          </a-col>
        </a-row>
      </a-col>
    </a-row>

    <a-tabs
      class="miles-cards-tabs"
      type="card"
      :defaultActiveKey="activeCreditCardsTab"
      @change="onChangeTabs"
    >
      <a-tab-pane key="ccard" tab="Dados do Portador" force-render>
        <div class="travel-drawer">
          <EditCompanyCreditCards
            :creditCard="creditCard"
            :viewOnly="viewOnly"
            :fieldSizes="{
              bank_id: 12,
              card_name: 6,
              card_number: 6,
              card_month: 4,
              card_year: 4,
              card_cvv: 4,
              card_limit: 6,
              status: 6,
              invoice_closing_date: 7,
              invoice_payment_date: 7,
              customer_id: 8,
              create_customer: 2,
            }"
            type="update"
            @updateCompanyCreditCard="getCompanyCreditCard()"
          />
        </div>
      </a-tab-pane>

      <a-tab-pane v-if="!viewOnly" key="extract" force-render>
        <template slot="tab">
          Extrato
          <a-tag class="ml-5" color="blue">
            {{
              companyCCardTransactions.pagination.total != 0
                ? companyCCardTransactions.pagination.total
                : 0
            }}
          </a-tag>
        </template>

        <a-row :gutter="10" class="mb-20" type="flex" justify="end">
          <a-col>
            <a-button
              size="small"
              @click="openManualEmission = true"
              style="font-size: 13px"
            >
              <a-icon type="logout" />
              Retirada manual
            </a-button></a-col
          >

          <a-col>
            <a-button
              size="small"
              @click="openPayBill = true"
              style="font-size: 13px"
            >
              <a-icon type="like" />
              Pagar Fatura
            </a-button></a-col
          >
        </a-row>

        <a-modal
          width="380px"
          :visible="openManualEmission"
          :footer="false"
          @cancel="openManualEmission = false"
        >
          <template slot="title">
            <a-icon class="mr-5 red" type="logout" /> RETIRADA MANUAL
          </template>

          <div v-if="openManualEmission">
            <div class="mt-10 relative">
              <a-form-item class="travel-input-outer">
                <label class="filled">Valor a ser retirado (R$)</label>
                <a-input
                  class="travel-input"
                  :placeholder="'Valor a ser retirado (R$)'"
                  v-currency="{
                    currency: {
                      prefix: '',
                      suffix: '',
                    },
                    distractionFree: false,
                    valueAsInteger: false,
                    precision: 2,
                    autoDecimalMode: true,
                    allowNegative: false,
                  }"
                  v-model="creditCardEmission.value"
                >
                  <template slot="prefix"> R$ </template>
                </a-input>
              </a-form-item>
            </div>

            <div class="mt-10 relative">
              <a-form-item class="travel-input-outer">
                <label class="filled">ID do Voyage</label>
                <a-input
                  class="travel-input"
                  :placeholder="'ID do Voyage'"
                  v-model="creditCardEmission.voyageId"
                >
                  <a-icon slot="prefix" type="field-svg" />
                </a-input>
              </a-form-item>
            </div>

            <div class="mt-10 relative">
              <a-textarea
                v-model="creditCardEmission.obs"
                placeholder="Observações"
                :auto-size="{ minRows: 3, maxRows: 5 }"
              />
            </div>

            <div class="mt-30 a-center">
              <a-popconfirm
                :title="
                  'Tem certeza que deseja retirar R$' +
                  creditCardEmission.value +
                  '?'
                "
                ok-text="Sim"
                cancel-text="Não"
                :disabled="creditCardEmission.value != undefined ? false : true"
                @confirm="confirmCreditCardEmission"
              >
                <a-button
                  :loading="creditCardEmission.loading"
                  :disabled="
                    creditCardEmission.value != undefined ? false : true
                  "
                  type="primary"
                >
                  CONFIRMAR RETIRADA
                </a-button>
              </a-popconfirm>
            </div>
          </div>
        </a-modal>

        <a-modal
          width="380px"
          :visible="openPayBill"
          :footer="false"
          @cancel="openPayBill = false"
        >
          <template slot="title">
            <a-icon class="mr-5 red" type="like" /> PAGAR FATURA
          </template>

          <div v-if="openPayBill">
            <div class="mt-10 relative">
              <a-form-item class="travel-input-outer">
                <label class="filled">Valor a ser pago (R$)</label>
                <a-input
                  class="travel-input"
                  :placeholder="'Valor a ser pago (R$)'"
                  v-currency="{
                    currency: {
                      prefix: '',
                      suffix: '',
                    },
                    distractionFree: false,
                    valueAsInteger: false,
                    precision: 2,
                    autoDecimalMode: true,
                    allowNegative: false,
                  }"
                  v-model="creditCardPayment.value"
                >
                  <template slot="prefix"> R$ </template>
                </a-input>
              </a-form-item>
            </div>

            <div class="mt-10 relative">
              <a-textarea
                v-model="creditCardPayment.obs"
                placeholder="Observações"
                :auto-size="{ minRows: 3, maxRows: 5 }"
              />
            </div>

            <div class="mt-30 a-center">
              <a-popconfirm
                :title="
                  'Tem certeza que deseja pagar R$' +
                  creditCardPayment.value +
                  '?'
                "
                ok-text="Sim"
                cancel-text="Não"
                :disabled="creditCardPayment.value != undefined ? false : true"
                @confirm="confirmCreditCardPayment"
              >
                <a-button
                  :loading="creditCardPayment.loading"
                  :disabled="
                    creditCardPayment.value != undefined ? false : true
                  "
                  type="primary"
                >
                  CONFIRMAR PAGAMENTO
                </a-button>
              </a-popconfirm>
            </div>
          </div>
        </a-modal>

        <a-row
          class="travel-filters mt-0 mb-0 pt-0 pb-0 bt-0 bb-0"
          :gutter="20"
        >
          <a-col :span="4">
            <div class="travel-input">
              <label class="filled">ID Operação</label>
              <a-input
                placeholder=""
                v-model="companyCCardTransactions.filters.operationId"
                @change="getCompanyCreditCardTransactions()"
              />
            </div>
          </a-col>
          <a-col :span="4">
            <div class="travel-input">
              <label class="filled">ID contrato</label>
              <a-input
                placeholder=""
                v-model="companyCCardTransactions.filters.contractId"
                @change="getCompanyCreditCardTransactions()"
              />
            </div>
          </a-col>
          <a-col :span="8">
            <div class="travel-input">
              <label class="filled">Operação</label>
              <a-select
                class="travel-input"
                placeholder="Selecione"
                mode="multiple"
                v-model="companyCCardTransactions.filters.operation.selected"
                show-search
                style="width: 100%"
                @change="getCompanyCreditCardTransactions()"
              >
                <a-select-option
                  v-for="(item, index) of companyCCardTransactions.filters
                    .operation.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>
          <a-col v-if="$root.isAdmin()" :span="8">
            <div class="travel-input">
              <label class="filled">Usuários</label>
              <a-select
                class="travel-input"
                placeholder="Selecione"
                mode="multiple"
                v-model="companyCCardTransactions.filters.users.selected"
                optionFilterProp="txt"
                show-search
                style="width: 100%"
                @change="getCompanyCreditCardTransactions()"
              >
                <a-select-option
                  v-for="(item, index) of companyCCardTransactions.filters.users
                    .list"
                  :key="index"
                  :value="item.id"
                  :txt="item.first_name"
                >
                  {{ item.first_name }}
                  {{ item.last_name }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>
        </a-row>

        <div class="travel-table last-itens">
          <HayaTable
            :data-source="companyCCardTransactions.list"
            :loading="companyCCardTransactions.loading"
            :pagination="false"
            :paginator="companyCCardTransactions.pagination"
            @getList="
              getCompanyCreditCardTransactions(
                companyCCardTransactions.pagination.page
              )
            "
            :columns="columns"
          >
            <div slot="voyage_id" slot-scope="record">
              {{ record.voyage_id }}
            </div>
            <div slot="total_miles" slot-scope="record">
              <div :class="record.is_canceled == '1' ? 'line-through' : ''">
                {{ formatMilesOperation(record) }}
              </div>
            </div>

            <div slot="user" slot-scope="record">
              {{ record.first_name }}
              {{ record.last_name }}
            </div>

            <div slot="value" slot-scope="record">
              <div :class="record.is_canceled == '1' ? 'line-through' : ''">
                {{ record.operation == "subtract" ? "-" : ""
                }}{{
                  record.operation == "add" || record.operation == "extortion"
                    ? "+"
                    : ""
                }}{{ record.value }}
              </div>
            </div>

            <div slot="operation" slot-scope="record">
              <span v-html="formatOperation(record.operation)" />
              <a-tooltip v-if="record.extra_info">
                <template slot="title">
                  {{ record.extra_info }}
                </template>
                <a-icon
                  class="ml-5 relative c-pointer"
                  style="font-size: 12px; color: #aaa; top: 2px"
                  type="info-circle"
                />
              </a-tooltip>
            </div>

            <div slot="contract_id" slot-scope="record">
              <div v-if="record.contract_id > 0">
                <a
                  :href="`/contracts/edit/${record.contract_id}`"
                  target="_blank"
                >
                  {{ record.contract_id }}
                </a>
              </div>
            </div>

            <template slot="created" slot-scope="record">
              {{ record.created }}
            </template>

            <div class="table-actions" slot="action" slot-scope="record">
              <a-dropdown
                overlayClassName="actions-dropdown"
                v-if="
                  ($root.isAdmin() && record.operation == 'add') ||
                  record.operation == 'new' ||
                  record.operation == 'subtract'
                "
                :trigger="['click']"
                placement="bottomRight"
              >
                <a style="font-size: 12px; color: #aaa">
                  <i class="fas fa-ellipsis-h"></i>
                </a>

                <a-menu slot="overlay">
                  <a-menu-item>
                    <a-popconfirm
                      v-if="
                        record.operation == 'subtract' ||
                        record.operation == 'add'
                      "
                      title="Tem certeza que deseja cancelar esta transação?"
                      ok-text="Sim"
                      cancel-text="Não"
                      @confirm="cancelCreditCardTransaction(record)"
                    >
                      <i class="far fa-times-circle mr-5"></i>
                      Cancelar transação
                    </a-popconfirm>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </HayaTable>
        </div>
      </a-tab-pane>

      <a-tab-pane v-if="!viewOnly" key="notes" force-render>
        <template slot="tab">
          Notas
          <a-tag class="ml-5" color="blue" :id="`count-notes-${creditCard.id}`">
            0
          </a-tag>
        </template>
        <Notes module="company-credit-card" :moduleId="creditCard.id" />
      </a-tab-pane>

      <a-tab-pane v-if="!viewOnly" key="logs" force-render>
        <template slot="tab">
          LOGS
          <a-tag class="ml-5" color="blue" :id="`count-logs-${creditCard.id}`">
            0
          </a-tag>
        </template>

        <div style="height: 420px; overflow: auto">
          <LogsTimelineV2
            class="mt-20"
            v-if="creditCard.id"
            :module="'company-credit-card'"
            :id="creditCard.id"
          />
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import HayaTable from "../../general/HayaTable.vue";
import companyCreditCardMixins from "@/mixins/company-credit-cards/companyCreditCardMixins.js";
import companyCCardTransactionsMixins from "@/mixins/company-credit-cards-transactions/companyCCardTransactionsMixins.js";
import EditCompanyCreditCards from "../forms/EditCompanyCreditCards.vue";
import formatThings from "@/mixins/general/formatThings";
import Notes from "../../general/Notes.vue";
import LogsTimelineV2 from "../../logs/LogsTimelineV2.vue";

export default {
  name: "CompanyCreditCardsModal",
  props: {
    creditCardId: String,
    viewOnly: Boolean,
  },
  mixins: [
    formatThings,
    companyCreditCardMixins,
    companyCCardTransactionsMixins,
  ],
  components: { EditCompanyCreditCards, Notes, HayaTable, LogsTimelineV2 },
  data() {
    return {
      loading: false,
      creditCard: {},
      creditCardEmission: {
        loading: false,
        value: undefined,
        voyageId: undefined,
        obs: undefined,
      },
      creditCardPayment: {
        loading: false,
        value: undefined,
        obs: undefined,
      },
      openManualEmission: false,
      openPayBill: false,
      activeCreditCardsTab: "ccard",
      columns: [
        {
          title: "ID Operação",
          dataIndex: "company_credit_card_transaction_id",
          key: "company_credit_card_transaction_id",
          class: "company_credit_card_transaction_id",
        },
        {
          title: "ID Voyage",
          scopedSlots: {
            customRender: "voyage_id",
          },
        },
        {
          title: `Valor (R$)`,
          scopedSlots: {
            customRender: "value",
          },
        },
        {
          title: "Operação",
          scopedSlots: {
            customRender: "operation",
          },
        },
        {
          title: "ID Contrato",
          scopedSlots: {
            customRender: "contract_id",
          },
        },
        {
          title: "Usuário",
          dataIndex: "user",
          key: "user",
          scopedSlots: {
            customRender: "user",
          },
        },
        {
          title: "Data/Hora",
          scopedSlots: {
            customRender: "created",
          },
        },
        {
          title: "",
          align: "right",
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  beforeMount() {
    this.companyCCardTransactions.pagination.total = 0;
  },
  mounted() {
    //
    this.getCompanyCreditCard();

    //
    this.$http
      .get("/user/read")
      .then(({ data }) => {
        this.companyCCardTransactions.filters.users.list = data;
      })
      .catch(({ response }) => {
        response;
      });
  },
  methods: {
    creditCardDates() {
      let html = "";

      let invoiceClosingDate = `${(
        "0" + this.creditCard.invoice_closing_date
      ).slice(-2)}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`;

      let invoicePaymentDate = `${(
        "0" + this.creditCard.invoice_payment_date
      ).slice(-2)}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`;

      html += `<div class="txt">Fechamento da Fatura: ${invoiceClosingDate}</div>`;
      html += `<div class="txt">Pagamento da Fatura: ${invoicePaymentDate}</div>`;

      return html;
    },
    getCompanyCreditCard() {
      this.$http
        .post(`/company-credit-card/details?id=${this.creditCardId}`)
        .then(({ data }) => {
          this.creditCard = data;
          this.companyCCardTransactions.filters.creditCardId =
            this.creditCardId;
          this.$emit("loadStatus", false);

          this.companyCCardTransactions.filters.creditCardId =
            this.creditCard.id;
          this.getCompanyCreditCardTransactions();
        })
        .catch(({ response }) => {
          response;
          this.$emit("loadStatus", false);
        });
    },
    onChangeTabs(tab) {
      this.activeCreditCardsTab = tab;

      if (tab == "extract") {
        this.companyCCardTransactions.filters.creditCardId = this.creditCard.id;
        this.getCompanyCreditCard();
        this.getCompanyCreditCardTransactions();
      }
    },
    cancelCreditCardTransaction(transaction) {
      transaction.raw.is_canceled = 1;
      transaction.raw.status = "canceled";
      transaction.raw.action = "cancel-credit-card-transaction";
      transaction.modified_by = {
        name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
        id: this.$store.state.userData.id,
      };

      this.$http
        .post("/company-credit-card-transactions/update", transaction.raw)
        .then(({ data }) => {
          this.getCompanyCreditCard();
          this.getCompanyCreditCardTransactions();
          this.$message.success(data.message);
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    confirmCreditCardPayment() {
      if (this.creditCardPayment.value) {
        let theValue = parseFloat(
          this.convertNumberToDatabase(this.creditCardPayment.value)
        );
        let available = parseFloat(this.creditCard.available_value);

        if (available + theValue <= parseFloat(this.creditCard.card_limit)) {
          this.creditCardPayment.loading = true;
          this.$http
            .post("/company-credit-card-transactions/create", {
              company_credit_card_id: this.creditCard.id,
              contract_id: 0,
              customer_id: 0,
              is_canceled: 0,
              user_id: this.$store.state.userData.id,
              value: this.convertNumberToDatabase(this.creditCardPayment.value),
              operation: "add",
              extra_info: this.creditCardPayment.obs,
            })
            .then(({ data }) => {
              this.getCompanyCreditCard();
              this.openPayBill = false;
              this.creditCardPayment.loading = false;
              this.creditCardPayment.value = undefined;
              this.$message.success(data.message);
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.creditCardPayment.value = undefined;
              this.creditCardPayment.loading = false;
            });
        } else {
          this.$message.warning(
            "O valor a ser pago não pode ser maior do que o limite do cartão."
          );
        }
      } else {
        this.$message.warning("Insira o valor a ser retirado.");
      }
    },
    confirmCreditCardEmission() {
      if (this.creditCardEmission.value) {
        console.log(
          parseFloat(
            this.convertNumberToDatabase(this.creditCardEmission.value)
          ),
          " <= ",
          parseFloat(this.creditCard.available_value)
        );
        if (
          parseFloat(
            this.convertNumberToDatabase(this.creditCardEmission.value)
          ) <= parseFloat(this.creditCard.available_value)
        ) {
          this.creditCardEmission.loading = true;
          this.$http
            .post("/company-credit-card-transactions/create", {
              company_credit_card_id: this.creditCard.id,
              contract_id: 0,
              customer_id: 0,
              is_canceled: 0,
              voyage_id: this.creditCardEmission.voyageId,
              user_id: this.$store.state.userData.id,
              value: this.convertNumberToDatabase(
                this.creditCardEmission.value
              ),
              operation: "subtract",
              extra_info: this.creditCardEmission.obs,
            })
            .then(({ data }) => {
              this.getCompanyCreditCard();
              this.openManualEmission = false;
              this.creditCardEmission.loading = false;
              this.creditCardEmission.value = undefined;
              this.creditCardEmission.voyageId = undefined;
              this.$message.success(data.message);
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.creditCardEmission.value = undefined;
              this.creditCardEmission.voyageId = undefined;
              this.creditCardEmission.loading = false;
            });
        } else {
          this.$message.warning(
            "O valor a ser retirado não pode ser maior do que o disponível."
          );
        }
      } else {
        this.$message.warning("Insira o valor a ser retirado.");
      }
    },
    formatOperation(operation) {
      if (operation == "subtract") {
        operation = `<font class="tag return">DÉBITO</font>`;
      }

      if (operation == "extortion") {
        operation = `<font class="tag ">EXTORNO</font>`;
      }

      if (operation == "add") {
        operation = `<font class="tag add">PAGAMENTO DE FATURA</font>`;
      }

      if (operation == "canceled") {
        operation = `<font class="tag subtract">CANCELADO</font>`;
      }

      return operation;
    },
  },
};
</script>
