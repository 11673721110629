import _ from "lodash";
_
export default {
    data() {
        return {
            companyCCardTransactions: {
                loading: false,
                pagination: {
                    page: 1,
                    perPage: 10,
                    total: 0,
                    totalPages: 0,
                },
                details: {},
                list: [],
                filters: {
                    users: {
                        list: [],
                        selected: [],
                    },
                    operation: {
                        list: [{
                            label: "Cancelado",
                            value: "canceled",
                        }, {
                            label: "Extorno",
                            value: "extortion",
                        }, {
                            label: "Pagamento de Fatura",
                            value: "add",
                        }, {
                            label: "Débito",
                            value: "subtract",
                        }],
                        selected: [],
                    },
                    period: {
                        selected: [],
                    },
                    creditCardId: "",
                    operationId: "",
                    contractId: "",
                    searchTerm: "",
                    order: "desc",
                    orderBy: "created",
                }
            }
        };
    },
    beforeCreate() {

    },
    methods: {
        changePage(current) {
            this.companyCCardTransactions.pagination.page = current;
            this.getCompanyCreditCardTransactions();
        },
        changePageSize(current, pageSize) {
            this.companyCCardTransactions.pagination.page = current;
            this.companyCCardTransactions.pagination.perPage = pageSize;
            this.getCompanyCreditCardTransactions();
        },
        getCompanyCreditCardTransactions(samePage) {

            let filters = "";

            this.companyCCardTransactions.loading = true;

            if (this.companyCCardTransactions.filters.searchTerm) {
                filters += `&s=${this.companyCCardTransactions.filters.searchTerm}`;
            }

            if (this.companyCCardTransactions.filters.contractId) {
                filters += `&contract_id=${this.companyCCardTransactions.filters.contractId}`;
            }

            if (this.companyCCardTransactions.filters.creditCardId) {
                filters += `&company_credit_card_id=${this.companyCCardTransactions.filters.creditCardId}`;
            }

            if (this.companyCCardTransactions.filters.operationId) {
                filters += `&company_credit_card_transaction_id=${this.companyCCardTransactions.filters.operationId}`;
            }

            if (this.companyCCardTransactions.filters.operation.selected.length > 0) {
                filters += `&operation=${this.companyCCardTransactions.filters.operation.selected}`;
            }

            if (this.companyCCardTransactions.filters.users.selected.length > 0) {
                filters += `&user_id=${this.companyCCardTransactions.filters.users.selected}`;
            }

            filters += `&order=${this.companyCCardTransactions.filters.order}&order-by=${this.companyCCardTransactions.filters.orderBy}`;

            this.$http
                .get(
                    `/company-credit-card-transactions/list?page=${this.companyCCardTransactions.pagination.page}&per_page=${this.companyCCardTransactions.pagination.perPage}${filters}`
                )
                .then(({
                    data
                }) => {
                    if (!samePage) {
                        this.companyCCardTransactions.pagination.page = 1;
                    }
                    this.companyCCardTransactions.pagination.total = data.meta.total;
                    this.companyCCardTransactions.pagination.totalPages = data.meta.total_pages;
                    this.companyCCardTransactions.list = data.data;
                    this.companyCCardTransactions.loading = false;
                })
                .catch(({
                    response
                }) => {
                    this.companyCCardTransactions.loading = false;
                    this.companyCCardTransactions.list = response.data.data;
                    this.companyCCardTransactions.pagination.total = response.data.meta.total;
                    this.companyCCardTransactions.pagination.totalPages = response.data.meta.total_pages;
                });
        },
        //getCompanyCreditCardTransaction(id) {
        //     this.$http
        //         .post("/company-credit-card-transactions/details", {
        //             id
        //         })
        //         .then(({
        //             data
        //         }) => {
        //             this.hotel = data;
        //             this.hotelForm.setFieldsValue(data)
        //             if (this.$route.path.split('/')[1] == "hotels") {
        //                 document.title = `${data.id} - ${data.first_name}  ${data.last_name}`;
        //             }
        //         })
        //         .catch(
        //             ({
        //                 response
        //             }) => {
        //                 response
        //             }
        //         )
        //         .finally(() => {
        //             //this.processing = false;
        //         });
        // }
    }

}