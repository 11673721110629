<template>
  <div>
    <aForm :form="form" @submit="handleSubmit">
      <a-row :gutter="20">
        <a-col :span="fieldSizes.bank_id">
          <a-form-item class="travel-input-outer">
            <label for :class="form.getFieldValue('bank_id') ? 'filled' : ''"
              >Banco Emissor</label
            >
            <a-select
              class="travel-input"
              show-search
              placeholder="Banco Emissor"
              :disabled="disableCCardFields()"
              v-decorator="[
                'bank_id',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-select-option
                v-for="(item, index) of banksList"
                :key="index"
                :value="item.id"
              >
                {{ item.number }} - {{ item.name }} | Conta:
                {{ item.account }} | Agência: {{ item.agency }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="fieldSizes.card_name">
          <a-form-item class="travel-input-outer">
            <label for :class="form.getFieldValue('card_name') ? 'filled' : ''"
              >Nome no cartão</label
            >
            <a-input
              class="travel-input"
              placeholder="Nome no cartão"
              :disabled="disableCCardFields()"
              v-decorator="[
                'card_name',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :span="fieldSizes.card_number">
          <a-form-item class="travel-input-outer">
            <label
              for
              :class="form.getFieldValue('card_number') ? 'filled' : ''"
              >Número do cartão</label
            >
            <a-input
              class="travel-input"
              placeholder="Número do cartão"
              v-mask="'#### #### #### ####'"
              v-decorator="[
                'card_number',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              :disabled="viewOnly"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :span="fieldSizes.card_month">
          <a-form-item class="travel-input-outer">
            <label for :class="form.getFieldValue(`card_month`) ? 'filled' : ''"
              >Mês de Expiração</label
            >
            <a-select
              class="travel-input select"
              placeholder="Mês de Expiração"
              v-decorator="[
                `card_month`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório.',
                    },
                  ],
                },
              ]"
              :disabled="viewOnly"
            >
              <a-select-option
                v-for="(item, index) of cardMonths"
                :key="index"
                :value="item"
              >
                {{ item }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="fieldSizes.card_year">
          <a-form-item class="travel-input-outer">
            <label for :class="form.getFieldValue(`card_year`) ? 'filled' : ''"
              >Ano de Expiração</label
            >
            <a-select
              class="travel-input select"
              placeholder="Ano"
              v-decorator="[
                `card_year`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório.',
                    },
                  ],
                },
              ]"
              :disabled="viewOnly"
            >
              <a-select-option
                v-for="(item, index) of cardYears"
                :key="index"
                :value="item"
              >
                {{ item }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="fieldSizes.card_cvv">
          <a-form-item class="travel-input-outer">
            <label for :class="form.getFieldValue('card_cvv') ? 'filled' : ''"
              >CVV</label
            >
            <a-input
              class="travel-input"
              placeholder="CVV"
              v-decorator="[
                'card_cvv',
                {
                  rules: [
                    {
                      required: false,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              :disabled="viewOnly"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :span="fieldSizes.card_limit">
          <a-form-item class="travel-input-outer">
            <label for :class="form.getFieldValue('card_limit') ? 'filled' : ''"
              >Limite do cartão (R$)</label
            >
            <a-input
              class="travel-input"
              v-currency
              placeholder="Limite do cartão (R$)"
              :disabled="type == 'update' ? false : false"
              v-decorator="[
                'card_limit',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :span="fieldSizes.status">
          <a-form-item class="travel-input-outer">
            <label for :class="form.getFieldValue(`status`) ? 'filled' : ''">
              Status
            </label>

            <a-select
              class="travel-input"
              placeholder="Status"
              optionFilterProp="txt"
              v-decorator="[
                `status`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              show-search
              :disabled="viewOnly"
              style="width: 100%"
            >
              <a-select-option
                v-for="(item, index) of status"
                :key="index"
                :value="item.label"
                :txt="item.label"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="fieldSizes.invoice_closing_date">
          <a-form-item class="travel-input-outer">
            <label
              for
              :class="
                form.getFieldValue(`invoice_closing_date`) ? 'filled' : ''
              "
            >
              Dia de Fechamento fatura
            </label>

            <a-select
              class="travel-input select"
              placeholder="Dia de fechamento fatura"
              :disabled="viewOnly"
              v-decorator="[
                `invoice_closing_date`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório.',
                    },
                  ],
                },
              ]"
            >
              <a-select-option
                v-for="(item, index) of days"
                :key="index"
                :value="item"
              >
                {{ item }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="fieldSizes.invoice_payment_date">
          <a-form-item class="travel-input-outer">
            <label
              for
              :class="
                form.getFieldValue(`invoice_payment_date`) ? 'filled' : ''
              "
            >
              Dia de Pagamento fatura
            </label>

            <a-select
              class="travel-input select"
              placeholder="Dia de pagamento fatura"
              :disabled="viewOnly"
              v-decorator="[
                `invoice_payment_date`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório.',
                    },
                  ],
                },
              ]"
            >
              <a-select-option
                v-for="(item, index) of days"
                :key="index"
                :value="item"
              >
                {{ item }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="fieldSizes.customer_id">
          <a-form-item class="travel-input-outer">
            <label
              for
              :class="form.getFieldValue(`customer_id`) ? 'filled' : ''"
            >
              Proprietário do cartão
            </label>

            <a-select
              class="travel-input"
              placeholder="Busque um proprietário"
              optionFilterProp="txt"
              v-decorator="[
                `customer_id`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              show-search
              style="width: 100%"
              @search="searchCustomers"
              :disabled="viewOnly"
            >
              <a-select-option
                v-for="(item, index) of customersList"
                :key="index"
                :value="item.id"
                :txt="item.first_name"
              >
                {{ item.id }} - {{ item.first_name }}
                {{ item.last_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col
          v-if="!viewOnly"
          :span="fieldSizes.create_customer"
          style="height: 64px"
        >
          <a-tooltip>
            <template slot="title"> Adicionar proprietário do cartão </template>
            <a-button
              type="primary"
              shape="circle"
              icon="user-add"
              @click="addCustomer"
            />
          </a-tooltip>
        </a-col>

        <a-col :span="24">
          <a-checkbox
            class="mb-20"
            v-model="showInContract"
            style="font-size: 13px; font-weight: 500; position: relative"
            :disabled="viewOnly"
          >
            Mostrar no contrato
          </a-checkbox>
        </a-col>

        <a-col :span="24" style="margin: 0 -8px">
          <AddressData
            :form="form"
            :fieldSizes="theFieldSizes()"
            :disabledCondition="viewOnly"
          />
        </a-col>

        <a-col v-if="!viewOnly" class="a-center mt-20" :span="24">
          <a-button
            size="large"
            type="primary"
            html-type="submit"
            :loading="loading"
          >
            {{ type == "create" ? "Cadastrar Cartão" : "Atualizar Cartão" }}
          </a-button>
        </a-col>
      </a-row>
    </aForm>

    <aModal v-model="openCreateCustomerModal" :footer="false">
      <template slot="title">
        <a-icon class="cprimary" type="user-add" /> CRIAR PROPRIETÁRIO DO CARTÃO
      </template>
      <a-form @submit.prevent="submitCustomer" :form="customerForm">
        <a-row
          class="box box-contractor"
          :gutter="[20, 0]"
          style="margin-top: -20px"
        >
          <CustomerForm
            v-if="openCreateCustomerModal"
            :customer="customer"
            :customerForm="customerForm"
          />

          <a-col class="a-center mt-20" :span="24">
            <a-button
              icon="user"
              type="primary"
              :loading="loadingSubmitCustomer"
              html-type="submit"
            >
              Cadastrar cliente
            </a-button>
          </a-col>
        </a-row>
      </a-form>
    </aModal>
  </div>
</template>

<script>
import CustomerForm from "../../customers/forms/CustomerForm.vue";
import AddressData from "../../general/AddressData.vue";
import banks from "@/json/banks.json";

export default {
  name: "EditCompanyCreditCards",
  props: {
    creditCard: Object,
    type: String,
    fieldSizes: Object,
    viewOnly: Boolean,
  },
  components: { CustomerForm, AddressData },
  data() {
    return {
      form: this.$form.createForm(this),
      customerForm: this.$form.createForm(this),
      openCreateCustomerModal: false,
      loadingSubmitCustomer: false,
      showInContract: false,
      airlinesList: [],
      customersList: [],
      usersList: [],
      banks,
      status: [
        {
          label: "Ativo",
          value: "Ativo",
        },
        {
          label: "Desativado",
          value: "Desativado",
        },
      ],
      loading: false,
      banksList: [],
      cardMonths: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      cardYears: [
        "2021",
        "2022",
        "2023",
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
        "2029",
        "2030",
        "2031",
        "2032",
        "2033",
        "2034",
        "2035",
        "2036",
        "2037",
        "2038",
        "2039",
        "2040",
      ],
      days: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      if (this.creditCard.bank_id != undefined) {
        if (this.creditCard.user != undefined) {
          this.customersList = [
            {
              id: this.creditCard.customer.id,
              first_name: this.creditCard.customer.first_name,
              last_name: this.creditCard.customer.last_name,
            },
          ];
          this.creditCard.customer_id = this.creditCard.customer.id;
          this.form.setFieldsValue(this.creditCard.raw);

          if (this.creditCard.show_in_contract != undefined) {
            if (this.creditCard.show_in_contract == 1) {
              this.showInContract = true;
            }
          }

          setTimeout(() => {
            this.form.setFieldsValue({
              card_limit: this.creditCard.raw.card_limit.replace(".", ","),
            });
          }, 100);
        }
      }
    }, 500);

    this.$http
      .get("/company-bank-accounts/list?page=1&per_page=100")
      .then(({ data }) => {
        this.banksList = data.data;
      });

    this.$http.get("/user/list?page=1&per_page=100").then(({ data }) => {
      this.usersList = data.data;
    });
  },
  methods: {
    theFieldSizes() {
      let sizes = {};

      if (this.type == "update") {
        sizes = {
          cep: 5,
          location_street: 8,
          location_number: 4,
          location_complement: 7,
          location_neighborhood: 8,
          location_city: 8,
          location_state: 4,
          location_country: 4,
        };
      } else {
        sizes = {
          cep: 10,
          location_street: 24,
          location_number: 8,
          location_complement: 16,
          location_neighborhood: 10,
          location_city: 10,
          location_state: 4,
          location_country: 10,
        };
      }

      return sizes;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (values.card_limit != "") {
            values.card_limit = values.card_limit
              .replace(".", "")
              .replace(".", "")
              .replace(".", "")
              .replace(".", "")
              .replace(",", ".");

            if (this.type == "create") {
              values.available_value = values.card_limit;
            }
          }

          values.modified_by = {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          };

          this.loading = true;

          if (this.type == "create") {
            values.user_id = this.$store.state.userData.id;

            values.show_in_contract = this.showInContract;

            this.$http
              .post("/company-credit-card/create", values)
              .then(({ data }) => {
                data;
                this.$message.success(data.message);
                this.loading = false;
                this.$emit("listCompanyCreditCardsAfterCreate", true);
              })
              .catch(({ response }) => {
                response;
                this.$message.error("Não foi possível criar o cartão.");
                this.loading = false;
              });
          }

          if (this.type == "update") {
            values.id = this.creditCard.id;
            values.user_id = this.creditCard.user.id;
            values.available_value = this.creditCard.available_value;
            values.spent_value = this.creditCard.spent_value;
            values.show_in_contract = this.showInContract;

            this.$http
              .post("/company-credit-card/update", values)
              .then(({ data }) => {
                data;
                this.$message.success(data.message);
                this.$emit("listCompanyCreditCardsAfterCreate", true);
                this.$emit("updateCompanyCreditCard", data);
                this.loading = false;
              })
              .catch(({ response }) => {
                response;
                this.$message.error("Não foi possível atualizar o cartão.");
                this.loading = false;
              });
          }
        }
      });
    },
    getCustomers(name, id) {
      this.$http
        .get(`/customer/list?page=1&per_page=2000&s=${name}&id=${id}`)
        .then(({ data }) => {
          this.customersList = data.data;
        });
    },
    searchCustomers(term, id) {
      this.getCustomers(term, id);
    },
    disableCCardFields() {
      let flag = false;
      return flag;
    },
    addCustomer() {
      this.openCreateCustomerModal = true;
    },
    submitCustomer() {
      this.customerForm.validateFields((err, customer) => {
        if (!err) {
          this.loadingSubmitCustomer = true;
          customer.action = "create-customer";
          customer.avatar = "";
          customer.status = 1;
          customer.user_id = this.$store.state.userData.id;
          customer.modified_by = {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          };

          this.$http
            .post("/customer/create", customer)
            .then(({ data }) => {
              this.$message.success(data.message);
              this.openCreateCustomerModal = false;
              this.getCustomers("", data.id);
              this.form.setFieldsValue({
                customer_id: data.id,
              });
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
            })
            .finally(() => {
              this.loadingSubmitCustomer = false;
            });
        }
      });
    },
  },
};
</script>
